import SEO from '@components/Common/SEO/index';
import FooterV2 from '@components/FooterV2/index';
import Navigation from '@components/Navigation/index';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Button from '@components/Button/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function TrialSessionPage(props) {
  return (
    <div>
      <SEO title={'Trial Session'} description={''}></SEO>
      <Navigation></Navigation>
      <div className="pt-24">
        <Hero></Hero>
        <Section></Section>
      </div>

      <FooterV2></FooterV2>
    </div>
  );
}

const Hero = () => {
  return (
    <div className="mt-4 flex items-center justify-around gap-2 bg-v5-green-500 px-4  py-8 md:gap-8 md:px-20">
      <h1 className="w-1/2 text-2xl font-semibold  text-white md:text-6xl md:!leading-tight">
        Welcome to
        <br className="hidden md:block" /> Crio’s Free Trial
        <br className="hidden md:block" /> Session!
      </h1>
      <div className="relative -mb-8 flex w-1/2 items-center justify-center">
        {/* <div className="absolute top-0 right-0"> */}
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../../images/TrialSessionHero.webp"
          placeholder="blurred"
          width={400}
          className="h-full"
          alt="Mobile Navbar"
        />
        {/* </div> */}
      </div>
    </div>
  );
};

const Section = () => {
  return (
    <div className="flex flex-col items-center justify-around gap-8 bg-v5-neutral-200 px-10 py-16">
      <h3 className="mb-6 text-center text-xl font-semibold leading-tight text-v5-green-700">
        Choose the relevant section below based on your profile to join the
        session.
      </h3>
      <div className="flex flex-col gap-8 md:flex-row">
        <SessionCard
          image={
            <div className="h-10 w-10 md:h-12 md:w-12">
              <FontAwesomeIcon
                className="h-full font-bold text-v5-green-300 transition-all	group-hover:translate-x-1"
                icon={['fas', 'briefcase']}
              ></FontAwesomeIcon>
            </div>
          }
          title={
            <>
              Intermediate/Advanced Session
              {/* <br className="hidden md:block" /> */}
            </>
          }
          subtitle={<>REST API</>}
          imageClassName="translate-y-2"
          description={
            'Join this session if you are currently working in the IT/Tech domain and want to upskill yourself to bag top product roles. In this session, you’ll understand how REST API calls are made. Our mentors will also answer your technical and career-related queries.'
          }
          link={'https://www.crio.do/magic/wp-session'}
        />
        <SessionCard
          image={
            <div className="h-12 w-12 md:h-14 md:w-14">
              <FontAwesomeIcon
                className="h-full font-bold text-v5-green-300 transition-all	group-hover:translate-x-1"
                icon={['fas', 'graduation-cap']}
              ></FontAwesomeIcon>
            </div>
          }
          title={<>Basic Session</>}
          subtitle={<>Web Developer Essentials</>}
          description={
            'Join this session if you want to start your career in the IT/Tech industry and understand how Crio can help you start a stellar career in Software Development. In this session made for beginners, you’ll get familiar with developer’s essentials and get a taste of how learn-by-doing happens at Crio.Do.'
          }
          link={'https://www.crio.do/magic/trial-session'}
        />
      </div>
    </div>
  );
};

const SessionCard = ({
  image,
  title,
  subtitle,
  description,
  imageClassName,
  link,
}) => {
  return (
    <div className="align-center flex max-w-[500px] flex-col items-center gap-4 rounded-lg bg-white p-8 font-manrope shadow-md">
      <div
        className={`flex w-[40px] items-center justify-center md:min-h-[60px] ${imageClassName}`}
      >
        {image}
      </div>
      <h3 className="flex-1 text-center text-2xl font-bold md:text-2xl">
        {title}
      </h3>
      <h4 className="text-center text-gray-600">{subtitle}</h4>
      <div className="mt-4 flex min-h-[100px] items-start justify-center text-center md:min-h-[150px]">
        <p className="text-center">{description}</p>
      </div>

      <a href={link}>
        <Button className="mt-4 flex w-full items-center justify-center gap-2 rounded-xl bg-v5-yellow-200">
          Join Now
          <FontAwesomeIcon
            className="text-lg font-bold transition-all	group-hover:translate-x-1"
            icon={['fas', 'angle-right']}
          ></FontAwesomeIcon>
        </Button>
      </a>
    </div>
  );
};
